import React from "react";
import clsx from "clsx";
import { Carousel, SplitCard } from "@molecules";
import { Container, Text, Button } from "@atoms";

const ServiceCarousel = ({ copy, slides, bgColor, className: _className }) => {
  return (
    <section
      className={clsx(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
          "bg-white": bgColor === "white",
        },
        _className
      )}
    >
      <Container>
        {copy && (
          <div className="mx-auto max-w-3xl">
            <Text
              richText
              className={clsx(
                "text-center prose-p:text-lg lg:prose-p:text-xl",
                {
                  "prose-dark": bgColor === "midnight",
                }
              )}
            >
              {copy}
            </Text>
          </div>
        )}
        <div
          className={clsx("mt-16", {
            "px-4 md:px-8": slides?.length > 1,
          })}
        >
          {slides?.length === 1 && (
            <SplitCard
              key={slides[0].uid}
              round
              className="mx-auto block w-full sm:w-2/3 md:w-1/2"
              {...slides[0]}
            />
          )}
          {slides?.length > 1 && (
            <Carousel minVisible={1} maxVisible={2} showInactive stretch>
              {slides.map(slide => {
                return (
                  <div className="h-full sm:px-4">
                    <SplitCard key={slide.uid} round {...slide} />
                  </div>
                );
              })}
            </Carousel>
          )}
          <div className="mt-16 flex items-center justify-center">
            <Button to="/impact-stories">View All</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

ServiceCarousel.defaultProps = {};

export default ServiceCarousel;
